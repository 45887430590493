<template>
  <div style="position: relative; height: 100vh" id="htmlTest"></div>
</template>
<script>
import commonFunction  from '../../mixin/commonFunction';
export default {
  name: "new_evaluation",
  mixins: [commonFunction],
  beforeMount(){
    if (localStorage.getItem("userToken")) {
        let res = this.$store.dispatch("auth/getAuthUser");
        res.then(value => {
          let userPermissions = value.user.roles.all_permission;
          let client_list = userPermissions.includes('evaluator-client-list');
          console.log('bbbb : ',client_list);
          if (client_list == false) {
            this.$toast.error('You do not have permission to start new evaluation', {
              position: "top-right",
              duration: 5000,
            });
           this.$router.push({ name: "403" });
          }
        });
    }
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    document.body.classList.add("viewreporthidden");
    this.getNewEvaluationLink();
  },
  data() {
    return {
      url_data: '',
      // url_data: 'https://survey.alchemer.com/s3/6916070/Ergo-Eval',
    };
  },
  methods: {
    getNewEvaluationLink() {
      var _this = this;
      var cid = _this.$route.params.cid;
      var uid = _this.$route.params.uid;
      console.log('cid',cid);
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/get-client-ergo-eval-link/" + cid +"/" +uid,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.url_data = decodedJson.ergo_eval_url;
          // Create a blob Url for hiding iframe src
          var blobMe = URL["createObjectURL"](
            new Blob([""], { type: "text/html" })
          );
          var elIframe = document["createElement"]("iframe");
          elIframe["setAttribute"]("frameborder", "0");
          elIframe["setAttribute"]("width", "100%");
          elIframe["setAttribute"]("height", "100%");
          elIframe["setAttribute"]("allowfullscreen", "true");
          elIframe["setAttribute"]("webkitallowfullscreen", "true");
          elIframe["setAttribute"]("mozallowfullscreen", "true");
          elIframe["setAttribute"](
            "style",
            "position: relative; top: 0; left: 0;"
          );
          elIframe["setAttribute"]("src", blobMe);
          var idOne = "gepa_" + Date.now();
          elIframe["setAttribute"]("id", idOne);
          document.getElementById("htmlTest").appendChild(elIframe);
          const iframeHere = _this.url_data;
          document["getElementById"](idOne)["contentWindow"]["document"].write(
            '<script type="text/javascript">location.href = "' +
              iframeHere +
              '";\x3c/script>'
          );
        })
        .catch(({ response }) => {
          if(response.status == 404) {
            this.$router.push({ name: "404" });
          }
          if(response.status == 401) {
            this.tokenExpired();
            this.$router.push({ name: "login" });
          }
          if(response.status == 403) {
            this.$toast.error('You do not have permission to start new evaluation', {
              position: "top-right",
              duration: 5000,
            });
            this.$router.push({ name: "403" });
          }
        });
    }
  },
};
</script>